module.exports = [
  {
    id: -1,
    name: 'deleted',
    up: 1,
    delete: true
  },
  {
    id: 1,
    name: 'wip',
    up: 5,
    down: -1,
    edit: true
  },
  {
    id: 5,
    name: '1st review',
    up: 7,
    down: 1,
    edit: true
  },
  {
    id: 7,
    name: '2nd review',
    up: 10,
    down: 5,
    edit: true
  },
  {
    id: 10,
    name: 'approved',
    down: 7
  }
]
